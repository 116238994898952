<template>
	<img
		v-if="type === 'minimize'"
		class="c-sidebar-brand-minimized"
		src="/assets/images/sidebar-brand-minimized.svg"
	>
	<img
		v-else
		class="c-sidebar-brand-full"
		src="/assets/images/sidebar-brand-full.svg"
	>
</template>

<script>
export default {
	name: 'Logo',
	props: {
		type: {
			type: String,
			default: 'minimize',
		},
	},
};
</script>
