export const SIDEBAR_NAME = {
	SALE_ORDERS: 'Sale orders',
	ORDERS: 'Orders',
	SHIPMENTS: 'Shipments',
	MARKETINGS: 'Marketings',
	CAMPAIGN_MANAGEMENT: 'Campaign management',
	PROMOTIONS: 'Promotions',
	FLASH_SALE: 'Flash sale',
	PRE_ORDERS: 'Pre-orders',
	PRODUCT_FEED_SETTING: 'Product feed setting',
	BUNDLE_SET: 'Bundle setting',
	CUSTOMERS: 'Customers',
	CUSTOMER_MANAGEMENT: 'Customer management',
	CUSTOMER_GROUPS: 'Customer groups',
	ABANDONED_CART: 'Abandoned cart',
	CATALOGS: 'Catalogs',
	PRODUCTS: 'Products',
	CATEGORIES: 'Categories',
	PRODUCT_LABELS: 'Product Labels',
	ATTRIBUTE_SETS: 'Attribute sets',
	ATTRIBUTE_KEYS: 'Attribute keys',
	WAREHOUSES: 'Warehouses',
	PAGE_SETTING: 'Page setting',
	HOMEPAGE: 'Homepage',
	SUB_HEADERS: 'Sub headers',
	STATIC_PAGE: 'Static page',
	PAYMENT_SETTING: 'Payment setting',
	SHIPPING_SETTING: 'Shipping setting',
	SEARCH_SETTING: 'Search setting',
	SEARCH_SCORE: 'Search score',
	SEARCH_SYNONYMS: 'Search synonyms',
	USERS: 'Users',
	USER_MANAGEMENT: 'User Management',
	ROLE_PERMISSION: 'Role & permission',
	HISTORY: 'History',
	AUDIT_LOG: 'Audit log',
	IMPORT_HISTORY: 'Import history',
	BACK_IN_STOCK_NOTIFICATIONS: 'Back-in-stock notifications',
	LIMIT_PRODUCT_PER_ACCOUNT: 'Limit Product',
	ON_SITE_MESSAGING: 'On-site messaging',
	CACHE: 'Cache',
	SEARCH_RESULT_CACHE: 'Search result cache',
	ORDER_AUTO_COMPLETE: 'Orders auto-complete',
};
